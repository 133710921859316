$(document).ready(function () {
    let band, perc, inset;
    let clipped = $('#tclip');
    let clipper= $('#pclip');
    if ( clipped.length && clipper.length ){
        clipping(clipped);
        $( window ).resize(function() {
            clipping(clipped);
        });
    }
    function clipping(clipped) {
        band = clipped.offset().left + clipped.width() - clipper.offset().left;
        perc = 100 - ( (band * 100) / clipped.width() );
        inset = '0 0 0 ' + perc + '%';
        clipped.attr('style','clip-path: inset(' + inset + ') !important');
    }

});

