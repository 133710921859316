$(document).ready(function () {

    let scroller = $('#header');
    let tuffatrice = $('#tuffatrice');
    let nuvole = $('#nuvole');
    let nuvole2 = $('#nuvole2');
    let scogli = $('#scogli');
    let mare = $('#mare');
    $('.picture_element').css('transform','translate(0,0) rotate(0deg) scale(1,1)');
    $('.picture_element').css('opacity','1');
    tuffatrice.css('transform','translate(-5vw, 0) rotate(0deg) scale(1,1) !important');

    $(window).scroll(function() {
        if ( !$('.picture_element').hasClass('parallax') ){
            $('.picture_element').addClass('parallax');
        }
        var stickyTop = $(window).scrollTop();
        if (stickyTop > 0 ) {
            scroller.addClass('sticky');
        } else {
            if( scroller.hasClass('sticky') ){
                scroller.removeClass('sticky');
            }
        }
        if (stickyTop > 50 ) {
            $('#title_container').css('top','100%');
        } else {
            $('#title_container').css('top','85%');
        }
        var k = stickyTop*0.5;
        var k1 = k*0.0025;
        var tc = 5 - k1*3;
        tuffatrice.css('transform','translate(-'+ tc +'vw,' + k*1.1 + 'px) rotate(' + k*0.3 +'deg) scale(' + (1 - k1*0.75) +',' + (1 - k1*0.75) +')');
        nuvole.css('transform','rotate(-' + k*0.2 +'deg) translate(' + k*2.5+ 'px,' + k*1.5 + 'px) scale(' + (1 + k1*3) +')');
        nuvole2.css('transform','rotate(-' + k*0.1 +'deg) translate(' + k*3.5 + 'px,' + k*4 + 'px)');
        scogli.css('transform','translateY(' + k*0.5 + 'px) scale(' + (1.1 - (k1 * 0.1)) +')');
        mare.css('transform','translateY(' + k*0.35 + 'px) scale(' + (1 + k1) +')');

        $('#home_sections').find('.picture_holder').each( function (e){
            var pic = $(this);
            var pictop =  pic.scrollTop();
            if ( stickyTop >= pictop + 150){
                pic.css('transform','translateX(0)');
            }
        });
    });

});
