$(document).ready(function(){
    let theForm = $('#form_rdg');
    let theLoader = $('#form_loader');
    let theModal = $("#form_modal");

    theForm.submit(function() {
        theLoader.fadeIn();
        $.ajax({
            data: $(this).serialize(),
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            error: function () {
                theLoader.fadeOut();
            }
        });
    });

    theForm.on("successed", function (e) {
        theModal.modal('show');
        theLoader.fadeOut();
    });
});