$(document).ready(function () {
    $('#mobile_menu_button > div').click( function (){
        $('#mobile_menu_button > div').toggleClass('displayed');
        $('#nav_container').toggleClass('displayed');
    });
    $('.accordion-button').click( function (){
        $('.accordion-button').not( $(this) ).removeClass('collapsed');
        $(this).toggleClass('collapsed');
    });
});
